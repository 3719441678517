import * as React from 'react';
import { graphql } from 'gatsby';
import Menu from '../components/Menu/Menu';
import ContactModule from '../components/ContactModule/ContactModule';
import Seo from '../components/Seo/Seo';
import PrivacyPolicy from "../components/privacyPolicy/PrivacyPolicy";

const PrivacyPolicyTemplate = ({
	                          data: {
		                          privacyPolicyData,
		                          contactData,
		                          datoCmsSite
	                          },
	                          pageContext,
}) => {
	const menuLogo = pageContext?.globals?.[0]?.menuLogo || null;
	const menuAltLogo = pageContext?.globals?.[0]?.menuAltLogo || null;
	const menuItems = pageContext?.globals?.[0]?.menuItems || null;
	const privacyPolicy = privacyPolicyData?.nodes?.[0] || null;
	const contactInfo = contactData?.nodes?.[0] || null;
	const socialItems = pageContext?.globals?.[0]?.socials || null;
	const seoGlobal = datoCmsSite?.globalSeo || null;
	const lang = pageContext?.locale || '';
	return (
		<>
			  <Seo seoGlobal={seoGlobal} />
			<Menu isPrivacyPolicy lang={lang} menuLogo={menuLogo} menuAltLogo={menuAltLogo} menuItems={menuItems} socialItems={socialItems} />
			<PrivacyPolicy lang={lang} data={privacyPolicy}/>
			<ContactModule lang={lang}  contactInfo={contactInfo} socialItems={socialItems} />
		</>
	);
};

export default PrivacyPolicyTemplate;
export const query = graphql`
	query ($locale: String) {
		privacyPolicyData: allDatoCmsPrivacyPolicy(filter: { locale: { eq: $locale } }) {
			nodes {
				mainHeader
				privacyPolicyContent {
					header
					description
				}
		}
	}
	contactData: allDatoCmsContact(filter: { locale: { eq: $locale } }) {
		nodes {
			contactHeader
			contactBackgroundText
			contactFirstYear
			contactBackground {
				url
			}
			contactPrivacyPolicyText
			contactPrivacyPolicyLink {
				slug
			}
		}
	}
	datoCmsSite {
		globalSeo {
			facebookPageUrl
			fallbackSeo {
				image {
					url
				}
				title
				twitterCard
				description
			}
			siteName
			titleSuffix
			twitterAccount
		}
	}
	}
`;
