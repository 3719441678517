import React from 'react';
import styled from 'styled-components';
import uuid from "react-uuid";
import PageContainer from "../../common/PageContainer";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const StyledWrapper = styled.div`
  	background-color: ${({ theme }) => theme.colors.creamy};
	position: relative;
	width: 100%;
`;


const StyledModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 190px 0 100px;
  width: 100%;
`

const StyledMainHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 38px;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 46px;
  margin-bottom: 50px;
  @media(min-width: 768px) {
    font-size: 54px;
    line-height: 60px;
  }
`

const StyledSinglePolicyBlockWrapper = styled.div`
  margin-bottom: 25px;
`

const StyledSingleBlockHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 29px;
  margin-bottom: 25px;
`

const StyledSingleBlockDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 29px;
  margin-bottom: 25px;
`

const PrivacyPolicy = ({lang, data}) => {
	const mainHeader = data?.mainHeader || '';
	const privacyPolicyContent = data?.privacyPolicyContent || [];
	return (
		<StyledWrapper>
			<PageContainer className="content-standard">
				<StyledModuleWrapper>
					<StyledMainHeader>{mainHeader}</StyledMainHeader>
					{privacyPolicyContent.map((item) => {
						const header = item?.header || '';
						const description = item?.description || '';
 					return (
	<StyledSinglePolicyBlockWrapper key={uuid()}>
								<StyledSingleBlockHeader>{header}</StyledSingleBlockHeader>
								<StyledSingleBlockDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
							</StyledSinglePolicyBlockWrapper>
						)
					})}
				</StyledModuleWrapper>
			</PageContainer>
		</StyledWrapper>
	);
};

export default PrivacyPolicy;
